import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Helmet from '../components/Helmet';
import Layout from '../components/Layout';
import Divider from '../components/Divider';
import WordPressContent from '../components/WordPressContent';
import Pagination from '../components/Pagination';
import Teaser from '../components/Teaser';
import DebugGrid from '../components/DebugGrid';
import { sanitizeTeaserTitle, sanitizeTeaserText } from '../utils/wordpressHelper';
import { addTrailingSlash } from '../utils/helper';

const propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        siteUrl: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    allWordpressPost: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          excerpt: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
          path: PropTypes.string.isRequired,
          categories: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string.isRequired,
              path: PropTypes.string.isRequired,
            }).isRequired,
          ).isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
    homePage: PropTypes.shape({
      content: PropTypes.string,
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    numPages: PropTypes.number.isRequired,
    pagePathHome: PropTypes.string.isRequired,
    pagePathPrefix: PropTypes.string.isRequired,
  }).isRequired,
};

const defaultProps = {

};

function Index({
  location: {
    pathname,
  },
  data: {
    site: {
      siteMetadata: {
        title: siteTitle,
        description,
        siteUrl,
      },
    },
    allWordpressPost: {
      nodes,
    },
    homePage,
  },
  pageContext: {
    currentPage,
    numPages,
    pagePathHome,
    pagePathPrefix,
  },
}) {
  const schemata = [
    {
      '@type': 'ItemList',
      itemListElement: nodes.map(({ path }, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        url: addTrailingSlash(`${siteUrl}${path}`),
      })),
    },
  ];

  return (
    <>
      <Helmet
        path={pathname}
        title={currentPage === 1 ? `${siteTitle} – ${description}` : `Seite ${currentPage}`}
        useTitleTemplate={currentPage !== 1}
        topLevelSchemata={schemata}
        next={currentPage < numPages - 1 ? `${pagePathPrefix}${currentPage + 1}/` : null}
        prev={currentPage > 1 ? `${pagePathPrefix}${currentPage - 1}/` : null}
      />
      <Layout printH1>
        {currentPage === 1 && homePage && homePage.content && (
          <>
            <div className="ft-grid ft-gap-margin ft-gap-margin--col">
              <WordPressContent content={homePage.content} />
            </div>
            <Divider />
          </>
        )}
        {nodes.map(({
          title, excerpt, slug, path, categories,
        }) => (
          <Teaser
            key={slug}
            url={path}
            title={sanitizeTeaserTitle(title)}
            text={sanitizeTeaserText(excerpt)}
            categories={
              categories && categories.length > 0
                ? categories.map(
                  ({ name: catName, path: catPath }) => ({ label: catName, url: catPath }),
                )
                : []
            }
          />
        ))}
        <Pagination
          currentPage={currentPage}
          numPages={numPages}
          pagePathHome={pagePathHome}
          pagePathPrefix={pagePathPrefix}
        />
      </Layout>
      <DebugGrid />
    </>
  );
}

export const query = graphql`
  query PostsQuery($limit: Int!, $skip: Int!) {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    allWordpressPost(
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        title
        excerpt
        slug
        path
        categories {
          path
          name
        }
      }
    }
    # TODO: Page ID is hardcoded, is there a way to use gatsby-config for that?
    homePage: wordpressPage(slug: {eq: "focus-theme-home"}) {
      content
    }
  }
`;

Index.propTypes = propTypes;
Index.defaultProps = defaultProps;

export default Index;
